<template>
  <div class="academicBackground">
      <div class="title">北京赋能青年科技中心</div>
      <div class="title2">天才在线旗下北京赋能青年科技中心，从人才成长的角度，持续研究青年能力素质</div>
      <div class="title2">提升的机理。为如何培养社会主义建设者和接班人提供理论依据与评价方法。</div>
      <div class="academicBackground_box">
        <div class="academicBackground_two">
          <img class="academicBackground_two_bg" src="../assets/img/academicBackground_bg2.png" alt="">
          <div class="two-box"></div>
          <div class="academicBackground_two_content">
                <div class="two_content_tit">负责人</div>
                <div class="two_content_item">
                    <img src="../assets/img/head1.png" alt="">
                    <div class="two_content_item_right">
                        <p class="two_content_item_right_p1"><span>宋洪峰</span> &nbsp;&nbsp;&nbsp;&nbsp;中心主任</p>
                        <p>中国人民大学博士，中国人力资源开发研究会人力资本服务分会联席秘书长、中国人民大学人力资本审计研究所副所长。专注于青年能力素质提升与人力资本成长模型的研究应用。</p>
                    </div>
                </div>
                <div class="two_content_item">
                    <div class="two_content_item_right">
                        <p class="two_content_item_right_p1 two_content_item2_right_p1">中心执行主任&nbsp;&nbsp;&nbsp;&nbsp;<span>莫琼</span></p>
                        <p>北京大学光华管理学院EMBA、国际行动学习CALF认证催化师。长期聚焦领导力发展与人才职业成长阶梯的研究。对人才增值有深厚的理论基础以及丰富的评估、培养经验。</p>
                    </div>
                    <img class="two_right_3" src="../assets/img/head2.png" alt="">
                </div>
         </div>
      </div>
      <div class="academicBackground_three">
          <img src="../assets/img/academicBackground_bg3.png" alt="">
          <div class="three_box">
              <div class="tit">主要研究成果</div>
              <div class="three_box_flex">
                  <div class="three_box_flex_item">
                      <div class="title_t">课题与模型研究成果</div>
                      <div class="title_b">
                          <p>青年能力素质辞典</p>
                          <p>青年能力素质三角计量法</p>
                          <p>全球视野下的第二课堂素质育人</p>
                          <p>青年能力素质三角计量法</p>
                          <p>......</p>
                      </div>
                  </div>
                  <div class="three_box_flex_item">
                      <div class="title_t">核心知识产权</div>
                      <div class="title_b">
                          <p>能力素质轨迹计量算法</p>
                          <p>能力素质画像系统</p>
                          <p>青年能力素质评价系统</p>
                          <p>职业能力匹配算法</p>
                          <p>......</p>
                      </div>
                  </div>
                  <div class="three_box_flex_item">
                      <div class="title_t">应用系统知识产权</div>
                      <div class="title_b">
                          <p>第二课堂能力素质评估系统</p>
                          <p>第二课堂社团管理系统</p>
                          <p>第二课堂社会实践管理系统</p>
                          <p>高校第二课堂成绩单系统</p>
                          <p>......</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      </div>
      <div class="academicBackground_four">
          <div class="title">课题成果简介</div>
          <div class="four_flex">
              <a href="https://mp.weixin.qq.com/s/SkS47TE05toNBodHBkQrrA" target='_blank'>
              <div class="item">
                  <p>团中央全国战略课题：高校共青团第二课堂育人机理研究</p>
                  <p><span>作者：宋洪峰、余晶莹</span>2019年8月</p>
              </div>
              </a>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'academicBackground',
}
</script>
<style lang="scss" scoped>
.academicBackground{
    width: 100%;
    background: url('../assets/img/academicBackground_bg1.png') no-repeat;
    background-size: cover;
    padding: 114px 0 119px 0;
    .title{
        color: #fff;
        font-size: 42px;
        font-weight: bold;
        margin-bottom: 58px;
    }
    .title2{
        width: 776px;
        text-align: left;
        margin: 0 auto;
        font-size: 20px;
        line-height: 30px;
        color: #fff;
    }
    .academicBackground_two{
        position: relative;
        .two-box{
            height: 620px;
            background: #fff;
        }
        .academicBackground_two_bg{
            margin-top: 101px;
            border:none;
            display: block;
            width: 100%;
        }
        .academicBackground_two_content{
            width: 1200px;
            position: absolute;
            left: 50%;
            margin-left: -600px;
            top: 110px;
            .two_content_tit{
                color: #20A8D9;
                font-size: 36px;
                font-weight: bold;
                margin-bottom: 60px;
            }
        }
        .two_content_item{
            width: 100%;
            height: 250px;
            box-shadow: 0 0 20px rgba(0,0,0,.1);
            border-radius: 19px;
            margin-bottom: 50px;
            display: flex;
            align-items: center;
            padding: 0 50px;
            img{
                width: 170px;
                height: 170px;
                margin-right: 40px;
            }
            .two_content_item_right{
                font-size: 24px;
                color: #666666;
                line-height: 34px;
                text-align: left;
            }
            .two_content_item_right_p1{
                font-size: 24px;
                color: #999999;
                display: flex;
                align-items: center;
                margin-bottom: 30px;
                span{
                    font-size: 32px;
                    font-weight: bold;
                    color: #333333;
                    margin-right: 10px;
                }
            }
            .two_content_item2_right_p1{
                text-align: right;
                justify-content: flex-end;
                span{
                    margin-left: 10px;
                }
            }
            .two_right_3{
                margin-right: 0;
                margin-left: 40px;
            }
        }
    }
}
.academicBackground_three{
    position: relative;
    img{
        width: 100%;
    }
    .three_box{
        position: absolute;
        width: 1200px;
        left: 50%;
        margin-left: -600px;
        top: 120px;
        .tit{
            font-size: 36px;
            color: #20A8D9;
            font-weight: bold;
            margin-bottom: 137px;
        }
        .three_box_flex{
            display: flex;
            align-items: center;
            padding: 0 68px;
            .three_box_flex_item{
                width: 311px;
                margin-right: 66px;
            }
            .three_box_flex_item:last-child{
                margin-right: 0;
            }
            .title_t{
               font-size: 22px;
               color: #fff;
               width: 311px;
               height: 54px;
               background-image: linear-gradient(to right, #01C7D8 , #20A8D9);
               line-height: 54px;
               border-radius: 10px 10px 0 0;
            }
            .title_b{
                text-align: left;
                p{
                    font-size: 18px;
                    line-height: 24px;
                    margin-top: 15px;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                    // cursor: pointer;
                }
                p:before{
                    content: '';
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    background: #2A2E3D;
                    border-radius: 50%;
                    margin-right: 10px;
                }
                p:last-child{
                    margin-left: 18px;
                }
                p:last-child:before{
                   display: none;
                }
            }
        }
    }
}
.academicBackground_four{
    .title{
       font-size: 36px;
       font-weight: bold;
       color: #fff;
       margin-top: 120px;
    }
    .four_flex{
        width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        .item{
           width: 383px;
           height: 172px;
           text-align: left;
           padding: 0 20px;
           background: #fff;
           border-radius: 10px;
           display: flex;
           flex-direction: column;
           justify-content: center;
          align-items: flex-start;
          margin-right: 25px;
          cursor: pointer;
           p:nth-child(1){
              color: #333333;
              font-size: 18px;
              font-weight: bold;
              margin-bottom: 20px;
           }
           p:nth-child(2){
               font-size: 14px;
               color: #999999;
               span{
                   margin-right: 20px;
               }
           }
        }
        .item:last-child{
           margin-right: 0;
        }
    }
}
</style>
